import * as React from 'react';
import { Rnd } from 'react-rnd';
import { createUseStyles } from 'react-jss';

import WindowTitle from './WindowTitle';
import { ActiveWindow } from 'models/window';
import { useCheckActiveWindow, useWindowSelector } from 'hooks/window';

interface StyleProps {
  z_index: number;
  is_active: boolean;
}

const useStyles = createUseStyles({
  window_parent: (props: StyleProps) => ({
    zIndex: props.z_index
  }),
  window: (props: StyleProps) => ({
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    height: '100%',
    width: '100%',
    background:
      'url(/grain.png), linear-gradient(to bottom, #666666 0, #444444 23px, #313131 50px)',
    backgroundRepeat: 'repeat',
    borderRadius: '10px',
    padding: '4px 4px 10px 4px',
    border: 'solid 1px black',
    boxShadow: props.is_active ? 'black 1pt 1pt 6px, #ddcc88 0px 0px 0px 2px' : 'black 0 0 2px'
  }),
  window_body: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 1px',
    marginTop: '4px',
    position: 'relative',
    minHeight: '0'
  }
});
// next thing to do: RP window title edit, and finally version change
const Window = (props: ActiveWindow): React.ReactElement => {
  const is_active_window = useCheckActiveWindow();
  const styles = useStyles({
    z_index: props.z_index,
    is_active: is_active_window(props.window_id)
  });
  const select_window = useWindowSelector();
  return (
    <Rnd
      className={styles.window_parent}
      default={{
        x: props.position.x,
        y: props.position.y,
        height: props.size.height,
        width: props.size.width
      }}
      enableResizing={{
        bottom: props.resizable,
        bottomRight: props.resizable,
        right: props.resizable
      }}
      dragHandleClassName='drag-handle'
      bounds='.workspace'
    >
      <div
        className={styles.window}
        onMouseDown={e => {
          select_window(props.window_id);
        }}
      >
        <WindowTitle window_id={props.window_id} on_close={props.on_close} />
        <div className={styles.window_body}>{props.children}</div>
      </div>
    </Rnd>
  );
};

export default Window;
