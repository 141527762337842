import * as React from 'react';
import { createUseStyles } from 'react-jss';

import { get_current_version } from 'models/version';

const useStyle = createUseStyles({
  credit: {
    opacity: 0.5,
    textAlign: 'right',
    fontSize: '10pt'
  }
});

export interface BuildInfoProps {
  className?: string;
}

const BuildInfo = ({ className }: BuildInfoProps): React.ReactElement => {
  const styles = useStyle();
  return (
    <div className={`${className} ${styles.credit} unselectable`}>
      <div>
        Brought to you by <i>Auriana Maraine</i> of Balmung.
        <br />
        Version {get_current_version().to_string()}
      </div>
      <a
        href='https://ko-fi.com/auriana_maraine'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src='/kofi.png' alt='Support me on Ko-fi!' />
      </a>
    </div>
  );
};
export default BuildInfo;