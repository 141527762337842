import * as React from 'react';

import Button, { ButtonType } from 'components/basic/Button';
import UseFormStyles from 'styles/FormStyles';
import { usePostCountOption } from 'hooks/options';

export interface PostCountOptionProps {}

const PostCountOption = (props: PostCountOptionProps): React.ReactElement => {
  const [is_visible, set_is_visible] = usePostCountOption();
  const styles = UseFormStyles();

  const checkbox_style = is_visible ? styles.checked : styles.unchecked;

  return (
    <Button
      type={ButtonType.FlatText}
      on_click={() => set_is_visible(!is_visible)}
    >
      <div className={`${styles.checkbox} ${checkbox_style}`}></div>
      Include split post count in text by default
    </Button>
  );
};

export default PostCountOption;
