import { ActiveWindow, WindowCollection } from 'models/window';
import { iRootState, StoreDispatch } from 'redux/store';
import { useSelector, useDispatch } from 'react-redux';

export const useWindowCreator = () => {
  const dispatch = useDispatch<StoreDispatch>();
  return (window: ActiveWindow) => {
    dispatch.window.create_window(window);
  };
};

export const useWindowSelector = () => {
  const dispatch = useDispatch<StoreDispatch>();
  return (window_id: string) => {
    dispatch.window.select_window(window_id);
  };
};

export const useWindowDestroyer = () => {
  const dispatch = useDispatch<StoreDispatch>();
  return (window_id: string) => {
    dispatch.window.destroy_window(window_id);
  };
};

export const useActiveWindows = (): WindowCollection => {
  const state = useSelector((state: iRootState) => state.window);
  return state.windows;
};

export const useCheckActiveWindow = () => {
  const state = useSelector((state: iRootState) => state.window);
  return (window_id: string): boolean => {
    return state.active_window_id === window_id;
  };
};
