import * as React from 'react';

import { useWindowCreator, useWindowDestroyer } from './window';
import { create_new_id } from 'util/rand';
import { ActiveWindow, WindowSection } from 'models/window';
import { Icons } from 'icons';
import TextInput from 'components/basic/TextInput';

export const useTextInputWindow = (): ((
  title: string,
  on_accept: (input: string) => void,
  accept_label: string,
  description?: string,
  on_decline?: () => void,
  decline_label?: string
) => void) => {
  const create_window = useWindowCreator();
  const destroy_window = useWindowDestroyer();
  const new_window_id = create_new_id();

  return (
    title: string,
    on_accept: (input: string) => void,
    accept_label: string,
    description?: string,
    on_decline?: () => void,
    decline_label?: string
  ) => {
    let window: ActiveWindow = {
      title: title,
      icon_path: Icons.TextInput,
      window_id: new_window_id,
      section: WindowSection.Unsectioned,
      size: { width: 300, height: 125 },
      position: { x: 400, y: 200 },
      resizable: false,
      children: (
        <TextInput
          window_id={new_window_id}
          description={description}
          on_accept={on_accept}
          accept_label={accept_label}
          on_decline={on_decline}
          decline_label={decline_label}
        />
      ),
      z_index: 0,
      on_close: (window_id: string) => {
        destroy_window(window_id);
      }
    };

    create_window(window);
  };
};
