import * as React from 'react';
import { createUseStyles } from 'react-jss';
import 'styles/contexify.css';

import ClearButton from './ClearButton';
import RoleplayTextView from './RoleplayTextView';
import ChannelButton from './ChannelButton';
import ActiveChannel from './ActiveChannel';
import SettingsButton from './SettingsButton';
import WordCount from './WordCount';
import EditorToggleButton from './EditorToggleButton';
import UseButtonStyles from 'styles/ButtonStyles';
import { useActiveChannel } from 'hooks/roleplay';

const useStyles = createUseStyles({
  action_bar: {
    flex: '0 0 24px',
    padding: {
      top: '19px'
    }
  },
  active_channel: {
    position: 'absolute',
    left: '20px',
    marginTop: '1px',
    textShadow: '0px 0px 1px #f0ebdf'
  },
  clear_button: {
    top: '0px',
    right: '0px'
  },
  roleplay_container: {
    display: 'flex',
    flex: '1 1 auto'
  },
  rp_text: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '15px',
    boxSizing: 'border-box',
    flex: '1 1 auto'
  },
  word_count: {
    position: 'absolute',
    right: '30px',
    marginTop: '1px',
    textShadow: '0px 0px 1px #f0ebdf'
  }
});

export interface RoleplaySessionWindowProps {
  session_id: string;
}

const RoleplaySessionWindow = ({
  session_id
}: RoleplaySessionWindowProps): React.ReactElement => {
  const set_channel = useActiveChannel(session_id)[1];

  const styles = {
    ...useStyles(),
    button: UseButtonStyles()
  };

  return (
    <>
      <div className={styles.action_bar}>
        <ChannelButton
          className={styles.button.bottom_padded}
          on_selected={channel => set_channel(channel)}
        />
        <SettingsButton
          className={styles.button.bottom_padded}
          session_id={session_id}
        />
        <EditorToggleButton
          className={styles.button.bottom_padded}
          session_id={session_id}
        />
      </div>
      <div className={styles.roleplay_container}>
        <RoleplayTextView className={styles.rp_text} session_id={session_id} />
        <ClearButton className={styles.clear_button} session_id={session_id} />
        <ActiveChannel
          className={`${styles.active_channel} unselectable`}
          session_id={session_id}
        />
        <WordCount
          className={`${styles.word_count} unselectable`}
          session_id={session_id}
        />
      </div>
    </>
  );
};

export default RoleplaySessionWindow;
