const id_length = 12;

export const create_new_id = (): string => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const len = characters.length;
  for (let i = 0; i < id_length; i++) {
    result += characters.charAt(Math.floor(Math.random() * len));
  }
  return result;
};
