import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';

import { StoreDispatch, iRootState } from 'redux/store';
import { ClockType, ClockStyle } from 'models/clock';
import {
  get_local_offset_minutes,
  get_clock_face,
  get_eorzea_time
} from './../models/clock';

const get_time = (state: iRootState, clock_type: ClockType): DateTime => {
  switch (clock_type) {
    case ClockType.Eorzea:
      return state.time.eorzea_time;
    case ClockType.Local:
      return state.time.world_time.plus({
        minute: get_local_offset_minutes()
      });
    case ClockType.Server:
    case ClockType.UTC:
    default:
      return state.time.world_time;
  }
};

export const useClockReader = (
  clock_type: ClockType,
  clock_style: ClockStyle
): string => {
  const clock_value = useSelector((state: iRootState) =>
    get_time(state, clock_type)
  );
  return get_clock_face(clock_value, clock_style);
};

export const useClockService = () => {
  const dispatch = useDispatch<StoreDispatch>();

  const set_clocks = () => {
    dispatch.time.set_world_time(DateTime.utc());
    dispatch.time.set_eorzea_time(get_eorzea_time());
  };

  set_clocks();
  setInterval(set_clocks, 1000);
};