import { DateTime } from 'luxon';

export interface ClockProps {
  type: ClockType;
  style: ClockStyle;
}

export enum ClockType {
  Local,
  Server,
  Eorzea,
  UTC
}

export enum ClockStyle {
  Half,
  Full,
  Military
}

const eorzea_factor: number = 144 / 7;

export const get_clock_label = (type: ClockType): string => {
  switch (type) {
    case ClockType.Server:
      return 'ST';
    case ClockType.Eorzea:
      return 'ET';
    case ClockType.Local:
    default:
      return 'LT';
  }
};

export const get_eorzea_time = (): DateTime => {
  let utc_epoch = DateTime.utc().toSeconds();
  let eorzea_epoch = utc_epoch * eorzea_factor;
  let eorzea_time = DateTime.fromSeconds(eorzea_epoch, {
    zone: 'utc'
  });
  return eorzea_time;
};

export const get_local_offset_minutes = (): number => {
  return DateTime.local().offset;
};

export const get_clock_face = (time: DateTime, style: ClockStyle): string => {
  let hours = time.hour;
  let minutes = time.minute.toString().padStart(2, '0');

  switch (style) {
    case ClockStyle.Half:
      let ampm = hours > 11 ? 'p.m.' : 'a.m.';
      hours = hours % 12 || 12;
      return `${hours}:${minutes} ${ampm}`.trim();
    case ClockStyle.Full:
      return `${hours}:${minutes}`.trim();
    case ClockStyle.Military:
      let hourString = hours.toString().padStart(2, '0');
      return `${hourString}${minutes}`.trim();
  }
};