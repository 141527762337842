import * as React from 'react';

import Button, { ButtonType } from 'components/basic/Button';
import { useOptionsWindow } from 'hooks/options';
import { useUpdatesWindow } from 'hooks/updates';
import { Icons } from 'icons';

import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  quickAccessContainer: {
    paddingTop: '5px'
  },
  quickAccessIcon: {
    height: '32px',
    width: '32px'
  },
  quickAccessIconForeground: {
    position: 'absolute',
    top: 0,
    left: '2px',
    right: '2px',
    bottom: '7px',
    '&:hover': {
      background: '#ffffff22'
    }
  }
});

export interface QuickAccessBarProps {
  className?: string;
}

const QuickAccessBar = ({ className }: QuickAccessBarProps) => {
  const styles = useStyles();
  const create_options_window = useOptionsWindow();
  const create_updates_window = useUpdatesWindow();

  return (
    <div className={`${className} ${styles.quickAccessContainer}`}>
      <Button
        type={ButtonType.FlatText}
        on_click={() => create_options_window()}
        tooltip='Options'
      >
        <div className={styles.quickAccessIconForeground} />
        <img
          className={styles.quickAccessIcon}
          src={Icons.Options}
          alt='Options'
        />
      </Button>
      <Button
        type={ButtonType.FlatText}
        on_click={() => create_updates_window()}
        tooltip='Updates'
      >
        <div className={styles.quickAccessIconForeground} />
        <img
          className={styles.quickAccessIcon}
          src={Icons.Updates}
          alt='Updates'
        />
      </Button>
    </div>
  );
};
export default QuickAccessBar;
