import * as React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  update_block: {
    padding: '10px'
  },
  header: {
    fontSize: 'large',
    fontWeight: 'bold'
  },
  subheader: {
    fontSize: 'large'
  },
  date: {
    fontSize: 'x-small'
  }
});

export interface Release0202Props {
  className?: string;
}

const Release0203 = ({ className }: Release0202Props) => {
  const styles = useStyles();

  return (
    <div>
      <div className={styles.update_block}>
        <hr />
        <div>
          <div className={styles.subheader}>
            2.3.0 <span className={styles.date}>(2021-05-30)</span>
          </div>
          Sorry that progress has been slow going! My day job has kept me pretty
          exhausted and made it hard to find energy to push forward.
          <br />
          <br />
          Needness to say, someone new was introduced to the site a couple of
          weeks ago and had some input regarding some issues with usability, so
          that got me thinking about improvements.
          <br />
          <br />
          I had originally planned some of these changes for a few updates in
          the future, but it's made more sense to get them in now to try and
          make the site on a whole more user friendly. This goes double for the
          3.0 update in the works.
          <br />
          <br />
          Short of bug fixes, this is the (for real this time) final patch of update 2.
          <br />
          <br />
          Changes:
          <br />
          <ul>
            <li>
              <i>Removed system menu from logo (was not obvious at all).</i>
            </li>
            <li>
              <i>
                Created a system tray on the left. The system tray will track
                all open windows as well as indicate the active window. (You can
                select from here too!)
              </i>
            </li>
            <li>
              <i>
                Moved actions from the system menu to a new quick access area in
                the top of the system tray.
              </i>
            </li>
            <li>
              <i>
                Improved windowing system. Windows can now move into the
                foreground when selected rather than staying in order of
                creation, and the window border will be highlighted when it's
                the active window.
              </i>
            </li>
            <li>
              <i>
                Roleplay windows can now be created using the plus icon in the
                corner of the Roleplays section of the system tray. New
                roleplays can also be named (It defaults to "Roleplay" if left
                blank).
              </i>
            </li>
          </ul>
          <br />
          <br />
          ~Auri &nbsp;
          <a href='mailto:auriana@ffxiv-toolkit.com'>
            &lt;auriana@ffxiv-toolkit.com&gt;
          </a>
        </div>
      </div>
    </div>
  );
};
export default Release0203;
