import React from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { Clear } from '@material-ui/icons';

import { iRootState } from 'redux/store';
import Button, { ButtonType } from '../Button';
import UseIconStyles from 'styles/IconStyles';

const useStyles = createUseStyles({
  title_container: {
    display: 'flex',
    flexDirection: 'row'
  },
  title: {
    font: {
      family: 'Abel',
      size: '14pt'
    },
    flex: '1 1 24px',
    color: '#cccccc',
    textShadow: '0px 1px 1px #000000',
    margin: '0px 4px 0px 4px',
    cursor: 'move'
  },
  title_border: {
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
    borderBottom: 'solid 1px #00000088',
    boxShadow: '0px 1px 1.5px -0.3px #cccccccc',
    margin: '-2px 4px 0px 4px'
  },
  close_button: {
    float: 'right'
  }
});

interface WindowTitleProps {
  window_id: string;
  on_close: (window_id: string) => void;
}

const WindowTitle = ({
  window_id,
  on_close
}: WindowTitleProps): React.ReactElement => {
  const styles = {
    pane: useStyles(),
    icon: UseIconStyles()
  };

  const title = useSelector((state: iRootState) => {
    return state.window.windows[window_id].title;
  });
  return (
    <>
      <div className={styles.pane.title_container}>
        <div className={`${styles.pane.title} drag-handle unselectable`}>
          {title}
        </div>
        <Button
          type={ButtonType.FlatIcon}
          on_click={() => {
            on_close && on_close(window_id);
          }}
        >
          <Clear className={styles.icon.face} />
        </Button>
      </div>
      <hr className={styles.pane.title_border} />
    </>
  );
};

export default WindowTitle;
