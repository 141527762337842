import * as React from 'react';

import { ChangeEvent } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import UseScrollbarStyles from 'styles/ScrollbarStyles';
import UseTextareaStyles from 'styles/TextareaStyles';
import { useInput, useInputVisibility, useIteration, useOutputVisibility } from 'hooks/roleplay';

interface RawInputViewProps {
  session_id: string;
}

const RawInputView = ({
  session_id
}: RawInputViewProps): React.ReactElement => {
  const textarea_styles = UseTextareaStyles();
  const scrollbar_styles = UseScrollbarStyles();

  const [input, set_input] = useInput(session_id);
  const iteration = useIteration(session_id)[0];
  const set_input_visbility = useInputVisibility(session_id)[1];
  const set_output_visbility = useOutputVisibility(session_id)[1];

  const [debouncedInputChange] = useDebouncedCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      set_input(event.target.value);
    },
    100,
    {
      maxWait: 200
    }
  );
  const on_input_change = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    debouncedInputChange(event);
    event.persist();
  };

  const on_key_down = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.ctrlKey && event.key === 'Enter') {
      set_input_visbility(false);
      set_output_visbility(true);
      event.stopPropagation();
      event.preventDefault();
    }
  };

  return (
    <textarea
      key={iteration}
      className={`${textarea_styles.textbox} ${scrollbar_styles.thin_scrollbar}`}
      spellCheck
      defaultValue={input}
      onChange={on_input_change}
      readOnly={false}
      placeholder='Start your RP post'
      onKeyDown={on_key_down}
    />
  );
};

export default RawInputView;
