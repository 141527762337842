import * as React from 'react';

import { useActiveChannel } from 'hooks/roleplay';

export interface ActiveChannelProps {
  session_id: string;
  className: string | undefined;
}

const ActiveChannel = ({
  session_id,
  className
}: ActiveChannelProps): React.ReactElement => {
  const channel = useActiveChannel(session_id)[0];

  return <div className={className}>Posting to: {channel.label}</div>;
};

export default ActiveChannel;
