import * as React from 'react';
import Slider from 'rc-slider';
import 'styles/slider.css';

import { useMaxPostLength } from 'hooks/options';
import { createUseStyles } from 'react-jss';
import { useDebouncedCallback } from 'use-debounce/lib';

const useStyles = createUseStyles({
  slider: {
    width: '150px',
    display: 'inline-block',
    marginLeft: '15px'
  }
});

export interface PostLengthOptionProps {}

const PostLengthOption = (props: PostLengthOptionProps): React.ReactElement => {
  const [max_post_length, set_max_post_length] = useMaxPostLength();
  const [local_post_length, set_local_post_length] = React.useState(
    max_post_length
  );
  const styles = useStyles();

  const [debouncedInputChange] = useDebouncedCallback(
    (value: number) => {
      set_max_post_length(value);
    },
    100,
    {
      maxWait: 200
    }
  );

  const on_change = (value: number) => {
    set_local_post_length(value);
    debouncedInputChange(value);
  };

  return (
    <>
      Maximum post length: {local_post_length}
      <Slider
        className={styles.slider}
        min={100}
        max={500}
        step={5}
        defaultValue={local_post_length}
        onChange={on_change}
      />
    </>
  );
};

export default PostLengthOption;
