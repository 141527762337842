import * as React from 'react';
import { createUseStyles } from 'react-jss';

import ClockContainer from './clock/ClockContainer';
import SystemLogo from './SystemLogo';

const useStyle = createUseStyles({
  title_bar: {
    position: 'relative',
    background: '#151715'
  },
  title: {
    float: 'left',
    font: {
      family: 'Abel',
      size: '18pt'
    }
  },
  logo: {
    height: '32px',
    marginRight: '6px',
    verticalAlign: 'top'
  },
  time: { float: 'right', margin: '2px 3px 0 0' },
  auth: {
    float: 'right',
    verticalAlign: 'middle',
    marginRight: '10px',
    marginTop: '1px'
  }
});

export interface TitleBarProps {
  className?: string;
}

const TitleBar = ({
  className
}: TitleBarProps): React.ReactElement => {
  const styles = useStyle();  
  
  return (
    <>
      <div className={`${className} ${styles.title_bar} unselectable`}>
        <div className={styles.title}>
          <SystemLogo className={styles.logo} />
          FFXIV Toolkit
        </div>
        <div className={styles.time}>
          <ClockContainer />
        </div>
        <div className={styles.auth}>
          <span className='display-name-button'></span>
          <span className='login-button'></span>
          <span className='signup-button'></span>
          <span className='login-loading-message'></span>
          <span></span>
        </div>
      </div>
    </>
  );
};
export default TitleBar;
