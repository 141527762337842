import * as React from 'react';
import {
  ClockProps,
  get_clock_label,
  ClockType,
  ClockStyle
} from 'models/clock';
import { createUseStyles } from 'react-jss';
import { useClockReader } from 'hooks/clock';

const useStyles = createUseStyles({
  clock: {
    display: 'inline-block',
    paddingLeft: '10px',
    fontSize: '10pt',
    '& *': {
      display: 'inline-block'
    }
  },
  label: {
    backgroundColor: '#eee1c5',
    letterSpacing: '1px',
    width: '22px',
    textAlign: 'center',
    borderRadius: '2.5px',
    lineHeight: '90%',
    color: '#604b0e',
    textShadow: '0 0 0.5px #bba66d',
    boxShadow: '0 0 4.5px #604b0e, 0 0 4.5px #604b0e'
  },
  value: {
    margin: '4px 4px 0 4px',
    textShadow:
      '0 0 2.5px #604b0e, 0 0 2.5px #604b0e, 0 0 2.5px #604b0e, 0 0 2.5px #604b0e, 0 0 2.5px #604b0e, 0 0 2.5px #604b0e'
  }
});

export interface ClockElement {
  key: string;
  type: ClockType;
  style: ClockStyle;
}

const Clock = (props: ClockProps): React.ReactElement => {
  const styles = useStyles();
  const label = get_clock_label(props.type);
  const value = useClockReader(props.type, props.style);

  return (
    <div className={`${styles.clock} unselectable`}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{value}</div>
    </div>
  );
};

export default Clock;
