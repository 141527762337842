import { ICopyable } from 'models/ICopyable';

export function useLocalStorage() {
  const get_object = <T>(key: string): T | null => {
    const val = window.localStorage.getItem(key);
    if (val === null) {
      return null;
    }
    return JSON.parse(val) as T;
  };

  const get_rich_object = <T extends ICopyable<T>>(
    target: T,
    key: string
  ): T | null => {
    const val = window.localStorage.getItem(key);
    if (val === null) {
      return null;
    }
    const value = JSON.parse(val) as T;
    target.copy_from(value);
    return target;
  };

  const get = <T extends string | number | boolean>(key: string): T | null => {
    const val = window.localStorage.getItem(key);
    if (val === null) {
      return null;
    }
    const value = JSON.parse(val) as T;
    return value;
  };

  const set = (key: string, data: any) => {
    window.localStorage.setItem(key, JSON.stringify(data));
  };

  return {
    get_object,
    get_rich_object,
    get,
    set
  };
}
