import * as React from 'react';
import { ModeComment } from '@material-ui/icons';
import { Item, Menu } from 'react-contexify';

import Button, { ButtonType } from 'components/basic/Button';
import { ChatChannel } from 'models/roleplay';
import UseButtonStyles from 'styles/ButtonStyles';
import UseIconStyles from 'styles/IconStyles';
import UseMenuStyles from 'styles/MenuStyles';
import { useChannelsList } from 'hooks/roleplay';
import { create_new_id } from 'util/rand';

export interface ChannelButtonProps {
  className?: string;
  on_selected: (channel: ChatChannel) => void;
}

const ChannelButton = ({
  className,
  on_selected
}: ChannelButtonProps): React.ReactElement => {
  const styles = {
    button: UseButtonStyles(),
    icon: UseIconStyles(),
    menu: UseMenuStyles()
  };
  const button_id = React.useState(create_new_id())[0];
  const channels = useChannelsList();
  const channel_button_menu_id = `channel_button_menu_${button_id}`;

  const menu_items = channels.map(channel => {
    return (
      <Item
        className={styles.menu.item}
        key={`${channel.tag} ${channel.label} ${channel.target}`}
        onClick={() => on_selected(channel)}
      >
        {channel.label}
      </Item>
    );
  });

  return (
    <>
      <Button
        className={className}
        type={ButtonType.Icon}
        menu_id={channel_button_menu_id}
        key='select_channel'
        tooltip='Select Chat Channel'
      >
        <ModeComment
          className={styles.icon.face}
        />
      </Button>
      <Menu id={channel_button_menu_id} className={styles.menu.body}>
        {menu_items}
        {/* <Separator /> */}
        {/* <Submenu className={styles.item} label='Foobar'>
      <Item className={styles.item} onClick={onClick}>Foo</Item>
      <Item className={styles.item} onClick={onClick}>Bar</Item>
    </Submenu> */}
      </Menu>
    </>
  );
};

export default ChannelButton;
