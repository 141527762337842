import * as React from 'react';
import { Clear } from '@material-ui/icons';

import Button, { ButtonType } from 'components/basic/Button';
import UseIconStyles from 'styles/IconStyles';
import {
  useInput,
  useInputVisibility,
  useIteration,
  useOutputVisibility
} from 'hooks/roleplay';
import { useConfirmOnClearOption } from '../../hooks/options';
import { useConfirmationWindow } from '../../hooks/confirmation';

export interface ClearButtonProps {
  session_id: string;
  className?: string;
}

const ClearButton = ({
                       session_id,
                       className
                     }: ClearButtonProps): React.ReactElement => {
  const styles = {
    icon: UseIconStyles()
  };

  const set_input_visibility = useInputVisibility(session_id)[1];
  const set_output_visibility = useOutputVisibility(session_id)[1];
  const set_input = useInput(session_id)[1];
  const set_new_iteration = useIteration(session_id)[1];
  const [confirm_on_clear] = useConfirmOnClearOption();
  const create_confirmation_window = useConfirmationWindow();

  const clear_input_click = () => {
    console.log(confirm_on_clear);
    if (confirm_on_clear) {
      create_confirmation_window('Confirm Clear',
        () => {
          clear_input();
        },
        'Clear',
        'Are you sure you want to clear this window\'s text?',
        () => {
        },
        'Cancel');
    } else {
      clear_input();
    }
  };

  const clear_input = () => {
    set_input('');
    set_new_iteration();
    set_input_visibility(true);
    set_output_visibility(false);
  };

  return (
    <Button
      type={ButtonType.Icon}
      className={className}
      absolute
      on_click={clear_input_click}
      tooltip='Clear Input'
    >
      <Clear className={styles.icon.face} />
    </Button>
  );
};

export default ClearButton;
