import * as React from 'react';

import { useWindowSelector } from "hooks/window";
import { createUseStyles } from "react-jss";
import Button, { ButtonType } from 'components/basic/Button';

const useTraySubitemStyles = createUseStyles({
  active: {
    background: '#fff2',
    boxShadow: '0px 0px 2px 2px #fff2'
  },
  icon: {
    height: '24px',
    width: '24px',
    verticalAlign: 'middle'
  },
  button: {
    paddingLeft: '20px',
    width: '100%',
    verticalAlign: 'middle',
    boxSizing: 'border-box'
  }
});

interface TraySubitemProps {
  className?: string;
  children?: any;
  window_id: string;
}

const TraySubitem = ({ className, children, window_id }: TraySubitemProps) => {
  let styles = useTraySubitemStyles();
  let select_window = useWindowSelector();
  return (
    <div className={className}>
      <Button
        className={styles.button}
        type={ButtonType.FlatText}
        on_click={() => select_window(window_id)}
      >
          {children}
      </Button>
    </div>
  );
};

export default TraySubitem;