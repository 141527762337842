import { createUseStyles } from 'react-jss';

const UseTextareaStyles = createUseStyles({
  textbox: {
    height: '100%',
    width: '100%',
    fontFamily: "Calibri, 'Trebuchet MS', sans-serif",
    background: '#272627',
    color: '#efe2c7',
    border: 0,
    boxShadow: 'inset -1px -1px 0.5px #ffffff22, inset 1px 1px 0.5px #00000022',
    resize: 'none',
    outline: '0 none',
    boxSizing: 'border-box',
    padding: '10px',
    borderRadius: '4px'
  }
});

export default UseTextareaStyles;
