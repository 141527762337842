import * as React from 'react';
import { Settings } from '@material-ui/icons';
import { Item, Menu } from 'react-contexify';

import Button, { ButtonType } from 'components/basic/Button';
import UseIconStyles from 'styles/IconStyles';
import UseMenuStyles from 'styles/MenuStyles';
import { usePostCountVisibility } from 'hooks/roleplay';

export interface SettingsButtonProps {
  session_id: string;
  className?: string;
}

const SettingsButton = ({
  session_id,
  className
}: SettingsButtonProps): React.ReactElement => {
  const styles = {
    icon: UseIconStyles(),
    menu: UseMenuStyles()
  };
  const [show_post_count, set_post_count_visibility] = usePostCountVisibility(session_id);

  const settings_button_menu_id = `settings_button_menu_${session_id}`;

  return (
    <>
      <Button
        className={className}
        type={ButtonType.Icon}
        menu_id={settings_button_menu_id}
        key='open_settings_dropdown'
        tooltip='Settings'
      >
        <Settings className={styles.icon.face} />
      </Button>
      <Menu id={settings_button_menu_id} className={styles.menu.body}>
        <Item
          className={styles.menu.item}
          onClick={() => {
            set_post_count_visibility(!show_post_count);
          }}
        >
          {show_post_count && '🗸'} Include split post count in text
        </Item>
      </Menu>
    </>
  );
};

export default SettingsButton;
