import * as React from 'react';

import { useWordCount } from 'hooks/roleplay';

export interface WordCountProps {
  session_id: string;
  className: string | undefined;
}

const WordCount = ({
  session_id,
  className
}: WordCountProps): React.ReactElement => {
  const word_count = useWordCount(session_id);

  return <div className={className}>Word count: {word_count}</div>;
};

export default WordCount;
