import { ActiveWindow, WindowCollection } from 'models/window';
import { createModel } from '@rematch/core';

export class WindowState {
  windows: WindowCollection;
  next_z_index: number;
  active_window_id: string;

  constructor() {
    this.windows = {};
    this.next_z_index = 1000;
    this.active_window_id = '';
  }
}

const create_window = (
  state: WindowState,
  payload: ActiveWindow
): WindowState => {
  const new_window: ActiveWindow = {
    ...payload,
    z_index: state.next_z_index++
  };

  let new_state = { ...state };
  new_state.windows[new_window.window_id] = new_window;
  new_state.active_window_id = new_window.window_id;
  return new_state;
};

const destroy_window = (state: WindowState, window_id: string) => {
  let new_state = { ...state };
  delete new_state.windows[window_id];
  // Not bothering with reassigning the active window if it was just deleted (unless it becomes a problem)
  return new_state;
};

const select_window = (state: WindowState, window_id: string) => {
  let new_state = { ...state };
  let selected_window = new_state.windows[window_id];
  selected_window.z_index = new_state.next_z_index++;
  new_state.active_window_id = window_id;
  return new_state;
};

const window = createModel({
  state: new WindowState(),
  reducers: {
    create_window,
    destroy_window,
    select_window
  }
});

export default window;
