import { init, RematchRootState } from '@rematch/core';

import desktop from './desktop';
import parser from './parser';
import roleplay from './roleplay';
import time from './time';
import window from './window';

const models = {
  desktop,
  parser,
  roleplay,
  time,
  window
};

const store = init({ models });

export default store;

export type StoreDispatch = typeof store.dispatch;
export type iRootState = RematchRootState<typeof models>;
