import * as React from 'react';
import { contextMenu } from 'react-contexify';

import { Icons } from 'icons';

export interface SystemLogoProps {
  className?: string;
}

const SystemLogo = ({ className }: SystemLogoProps): React.ReactElement => {
  return (
    <>
      <img
        className={className}
        src={Icons.Logo}
        alt='FFXIV Toolkit'
        onClick={event => {
          contextMenu.show({ id: 'system-menu', event });
        }}
      />
      {/* <Menu id='system-menu' className={styles.menu.body}>
        <Item
          className={`${styles.menu.item} ${styles.text.item_color}`}
          onClick={() => {
            create_roleplay_window();
          }}
        >
          New Roleplay Window
        </Item>
        <Item
          className={`${styles.menu.item} ${styles.text.item_color}`}
          onClick={() => {
            open_options_window();
          }}
        >
          Options
        </Item>
        <Item
          className={`${styles.menu.item} ${styles.text.item_color}`}
          onClick={() => {
            open_updates_window();
          }}
        >
          Updates History
        </Item>
        {/* <Separator />
        <Item
          className={menu_styles.item}
          onClick={() => {
            create_roleplay_window();
          }}
        >
          Options
        </Item> */}
        {/* <Submenu className={styles.item} label='Foobar'>
      <Item className={styles.item} onClick={onClick}>Foo</Item>
      <Item className={styles.item} onClick={onClick}>Bar</Item>
    </Submenu> */}
      { /*/Menu> */}
    </>
  );
};
export default SystemLogo;
