import { create_new_id } from 'util/rand';

export class ChatChannel {
  public label: string;
  public tag: string;
  public target: string;

  constructor(label: string, literal: string, target?: string | null) {
    this.label = label;
    this.tag = literal;
    this.target = target ?? '';
  }
}

export class RoleplaySession {
  public session_id: string;
  private _channel: ChatChannel;
  public word_count: number;
  public show_post_count: boolean;
  public show_input: boolean;
  public show_output: boolean;
  private _input: string;

  public get channel(): ChatChannel {
    return this._channel;
  }

  public set channel(val: ChatChannel) {
    this._channel = val;
  }

  public get input(): string {
    return this._input;
  }

  public set input(val: string) {
    this._input = val;
  }

  public iteration: string;

  constructor(
    session_id: string,
    channel: ChatChannel,
    show_post_count: boolean,
    show_input: boolean,
    show_output: boolean,
    input: string
  ) {
    this.session_id = session_id;
    this._channel = channel;
    this.channel = channel;
    this.show_post_count = show_post_count;
    this.show_input = show_input;
    this.show_output = show_output;
    this._input = '';
    this.input = input;
    this.word_count = 0;
    this.iteration = 'new';
  }

  public static new(session_id?: string): RoleplaySession {
    return new RoleplaySession(
      session_id ?? create_new_id(),
      new ChatChannel('Say', 's'),
      true,
      true,
      false,
      ''
    );
  }
}
