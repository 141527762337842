export interface ActiveWindow {
  window_id: string;
  title: string;
  icon_path: string;
  section: WindowSection;
  position: {
    x: number;
    y: number;
  };
  size: { width: number; height: number };
  resizable: boolean;
  z_index: number;
  children: JSX.Element;
  on_close: (window_id: string) => void;
}

export interface WindowCollection {
  [window_key: string]: ActiveWindow;
}

export enum WindowSection {
  Roleplay,
  Unsectioned
}

export interface Coordinates {
  x: number;
  y: number;
}

export interface BoxSize {
  height: number;
  width: number;
}