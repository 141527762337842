import { DateTime } from 'luxon';
import { createModel } from '@rematch/core';

export class TimeState {
  world_time: DateTime;
  eorzea_time: DateTime;

  constructor() {
    this.world_time = DateTime.utc(0, 1, 1);
    this.eorzea_time = DateTime.utc(0, 1, 1);
  }
}

const set_world_time = (state: TimeState, date: DateTime): TimeState => {
  // Realistically when the time changes, year through hour
  // will never change unless minute does
  if (state.world_time.minute !== date.minute) {
    return {
      ...state,
      world_time: date
    };
  } else {
    return state;
  }
};

const set_eorzea_time = (state: TimeState, date: DateTime): TimeState => {
  if (state.eorzea_time.minute !== date.minute) {
    return {
      ...state,
      eorzea_time: date
    };
  } else {
    return state;
  }
};

const time = createModel({
  state: new TimeState(),
  reducers: {
    set_eorzea_time,
    set_world_time
  },
  effects: {}
});

export default time;
