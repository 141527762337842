import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { iRootState, StoreDispatch } from 'redux/store';
import { ChatChannel, RoleplaySession } from 'models/roleplay';
import { Parser, PostCollection } from 'models/author';
import RoleplaySessionWindow from 'components/roleplay/RoleplaySessionWindow';
import {
  useDefaultActiveChannel,
  useMaxPostLength,
  usePostCountOption
} from './options';
import { useWindowCreator, useWindowDestroyer } from './window';
import { create_new_id } from 'util/rand';
import { ActiveWindow, WindowSection } from 'models/window';

export const useRoleplayCreator = (): ((title: string) => void) => {
  const dispatch = useDispatch<StoreDispatch>();
  const create_window = useWindowCreator();
  const destroy_window = useWindowDestroyer();

  const option_defaults = {
    show_post_count: usePostCountOption()[0],
    chat_channel: useDefaultActiveChannel()[0]
  };

  const create_roleplay_session = (): RoleplaySession => {
    const session = RoleplaySession.new();
    session.show_post_count = option_defaults.show_post_count;
    session.channel = option_defaults.chat_channel;
    dispatch.roleplay.add_session(session);
    return session;
  };

  return (title: string) => {
    const session = create_roleplay_session();
    const new_window_id = create_new_id();

    let window: ActiveWindow = {
      title: title || 'Roleplay',
      icon_path: '/roleplays_icon.png',
      window_id: new_window_id,
      section: WindowSection.Roleplay,
      size: { width: 650, height: 550 },
      position: { x: 250, y: 100 },
      resizable: true,
      children: <RoleplaySessionWindow session_id={session.session_id} />,
      z_index: 0,
      on_close: (window_id: string) => {
        destroy_window(window_id);
        dispatch.roleplay.remove_session(session.session_id);
      }
    };

    create_window(window);
  };
};

export const useChannelsList = (): ChatChannel[] => {
  return [
    new ChatChannel('Say', 's'),
    new ChatChannel('Party', 'p'),
    new ChatChannel('Alliance', 'a'),
    new ChatChannel('Yell', 'y'),
    new ChatChannel('Shout', 'sh'),
    new ChatChannel('Free Company', 'fc'),
    new ChatChannel('Emote', 'em')
  ];
};

export const useActiveChannel = (
  session_id: string
): [ChatChannel, (channel: ChatChannel) => void] => {
  const dispatch = useDispatch<StoreDispatch>();
  const channel = useSelector((state: iRootState) => {
    const session = state.roleplay.sessions[session_id];
    return session?.channel ?? new ChatChannel('Say', 's');
  });
  const set_channel = (channel: ChatChannel) => {
    dispatch.roleplay.set_chat_channel({ session_id, channel });
  };
  return [channel, set_channel];
};

export const useInputVisibility = (
  session_id: string
): [boolean, (value: boolean) => void] => {
  const dispatch = useDispatch<StoreDispatch>();
  const is_visible = useSelector((state: iRootState) => {
    const session = state.roleplay.sessions[session_id];
    return session?.show_input ?? true;
  });
  const set_is_visible = (is_visible: boolean) => {
    dispatch.roleplay.set_input_visibility({ session_id, is_visible });
  };
  return [is_visible, set_is_visible];
};

export const useOutputVisibility = (
  session_id: string
): [boolean, (value: boolean) => void] => {
  const dispatch = useDispatch<StoreDispatch>();
  const is_visible = useSelector((state: iRootState) => {
    const session = state.roleplay.sessions[session_id];
    return session?.show_output ?? false;
  });
  const set_is_visible = (is_visible: boolean) => {
    dispatch.roleplay.set_output_visibility({ session_id, is_visible });
  };
  return [is_visible, set_is_visible];
};

export const useInput = (
  session_id: string
): [string, (value: string) => void] => {
  const dispatch = useDispatch<StoreDispatch>();
  const input = useSelector((state: iRootState) => {
    const session = state.roleplay.sessions[session_id];
    return session?.input ?? '';
  });
  const set_input = (input: string) => {
    dispatch.roleplay.set_input({ session_id, input });
  };
  return [input, set_input];
};

export const useIteration = (session_id: string): [string, () => void] => {
  const dispatch = useDispatch<StoreDispatch>();
  const iteration = useSelector((state: iRootState) => {
    const session = state.roleplay.sessions[session_id];
    return session?.iteration ?? 'new';
  });
  const set_new_iteration = () => {
    dispatch.roleplay.set_new_iteration({ session_id });
  };
  return [iteration, set_new_iteration];
};

export const usePostCountVisibility = (
  session_id: string
): [boolean, (value: boolean) => void] => {
  const dispatch = useDispatch<StoreDispatch>();
  const is_visible = useSelector((state: iRootState) => {
    const session = state.roleplay.sessions[session_id];
    return session?.show_post_count ?? true;
  });
  const set_is_visible = (is_visible: boolean) => {
    dispatch.roleplay.set_post_count_visibility({ session_id, is_visible });
  };
  return [is_visible, set_is_visible];
};

export const usePostCollection = (session_id: string): PostCollection => {
  const max_post_length = useMaxPostLength()[0];
  const active_channel = useActiveChannel(session_id)[0];
  const input = useSelector((state: iRootState) => {
    const session = state.roleplay.sessions[session_id];
    return session?.input;
  });

  if (input) {
    const parser = Parser.new(max_post_length);
    parser.channel = active_channel.tag;
    return parser.generate_posts(input);
  }
  return new PostCollection('s', 1);
};

export const useWordCount = (session_id: string): number => {
  return useSelector((state: iRootState) => {
    const session = state.roleplay.sessions[session_id];
    return session?.word_count ?? 0;
  });
};
