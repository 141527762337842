import * as React from 'react';

import Button, { ButtonType } from 'components/basic/Button';
import UseFormStyles from 'styles/FormStyles';
import { useServerClockOption } from 'hooks/options';

export interface ShowServerTimeOptionProps {}

const ShowServerTimeOption = (props: ShowServerTimeOptionProps): React.ReactElement => {
  const [is_visible, set_is_visible] = useServerClockOption();
  const styles = UseFormStyles();

  const checkbox_style = is_visible ? styles.checked : styles.unchecked;

  return (
    <Button
      type={ButtonType.FlatText}
      on_click={() => set_is_visible(!is_visible)}
    >
      <div className={`${styles.checkbox} ${checkbox_style}`}></div>
      Show server clock
    </Button>
  );
};

export default ShowServerTimeOption;
