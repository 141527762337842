import * as React from 'react';

import { createUseStyles } from 'react-jss';
import ProcessedTextRow from './ProcessedTextRow';
import { usePostCollection } from 'hooks/roleplay';
import UseScrollbarStyles from 'styles/ScrollbarStyles';

const useStyles = createUseStyles({
  post: {
    display: 'flex',
    flexDirection: 'row',
    width: 'calc(100% - 50px)'
  },
  post_text: {
    flex: '1 1 auto'
  },
  copy_button: {
    flex: '0 0 50px'
  },
  rp_posts: {
    verticalAlign: 'top',
    marginTop: '10px',
    marginLeft: '5px',
    paddingRight: '10px',
    overflowY: 'scroll'
  },
  copied: {
    opacity: '0.25'
  }
});

interface ProcessedTextViewProps {
  session_id: string;
}

const ProcessedTextView = ({
  session_id
}: ProcessedTextViewProps): React.ReactElement => {
  const styles = useStyles();
  const scrollbar_styles = UseScrollbarStyles();
  const post_collection = usePostCollection(session_id);

  // Issue: the key will not be unique between lines if the text is the same
  return (
    <div className={`${styles.rp_posts} ${scrollbar_styles.thin_scrollbar}`}>
      {post_collection.posts.map(post => {
        return (
          <ProcessedTextRow
            session_id={session_id}
            post={post}
            key={post.full_text}
          />
        );
      })}
    </div>
  );
};

export default ProcessedTextView;
