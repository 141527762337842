import * as React from 'react';
import { createUseStyles } from 'react-jss';

import UseTextStyles from 'styles/TextStyles';
import ShowEorzeanTimeOption from './ShowEorzeanTimeOption';
import ShowLocalTimeOption from './ShowLocalTimeOption';
import ShowServerTimeOption from './ShowServerTimeOption';

const useStyles = createUseStyles({
  header: {
    marginTop: '10px',
    marginBottom: '6px'
  },
  option: {
    paddingLeft: '16px',
    marginBottom: '3px'
  }
});

export interface DesktopOptionsPanelProps {}

const DesktopOptionsPanel = (props: DesktopOptionsPanelProps): React.ReactElement => {

  const styles = {
    general: useStyles(),
    text: UseTextStyles()
  };

  return (
    <div>
      <div className={`${styles.text.header_color} ${styles.general.header}`}>Clocks</div>
      <div className={styles.general.option}>
        <ShowEorzeanTimeOption />
      </div>
      <div className={styles.general.option}>
        <ShowLocalTimeOption />
      </div>
      <div className={styles.general.option}>
        <ShowServerTimeOption />
      </div>
    </div>
  );
};

export default DesktopOptionsPanel;
