import { createUseStyles } from 'react-jss';

const UseTextStyles = createUseStyles({
  header_color: {
    color: '#cecece',
    textShadow: '0px 0px 1.5px black, 0px 0px 1.5px black, 0px 0px 1.5px black'
  },
  item_color: {
    color: '#efe2c7',
    textShadow: '0px 0px 1.5px black, 0px 0px 1.5px black, 0px 0px 1.5px black'
  }
});

export default UseTextStyles;
