import { useWindowDestroyer } from 'hooks/window';
import * as React from 'react';
import { createUseStyles } from 'react-jss';

import Button, { ButtonType } from './Button';

const useStyles = createUseStyles({
  contentContainer: {
    width: '100%',
    padding: 5
  },
  description: {
    marginTop: 5,
    marginBottom: 5
  },
  buttons: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 10,
    marginBottom: 5,
    textAlign: 'right'
  },
  button: {
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: 15
  }
});

interface ConfirmationProps {
  window_id: string;
  description?: string;
  on_accept: () => void;
  accept_label: string;
  on_decline?: () => void;
  decline_label?: string;
}

const Confirmation = ({
  window_id,
  description,
  on_accept,
  accept_label,
  on_decline,
  decline_label
}: ConfirmationProps): React.ReactElement => {
  const styles = useStyles();
  const [has_focused, set_has_focused] = React.useState(false);
  const destroy_window = useWindowDestroyer();
  const input_ref = React.useRef<HTMLInputElement | null>(null);

  React.useLayoutEffect(() => {
    if (!has_focused) {
      input_ref?.current?.focus();
      set_has_focused(true);
    }
  }, [has_focused]);

  const accept_action = () => {
    on_accept();
    destroy_window(window_id);
  };

  const decline_action = () => {
    on_decline && on_decline();
    destroy_window(window_id);
  };

  return (
    <div className={styles.contentContainer}>
      {description && <div className={styles.description}>{description}</div>}
      <div className={styles.buttons}>
        {decline_label && (
          <Button
            className={styles.button}
            type={ButtonType.Text}
            on_click={decline_action}
          >
            {decline_label}
          </Button>
        )}
        <Button
          className={styles.button}
          type={ButtonType.Text}
          on_click={accept_action}
        >
          {accept_label}
        </Button>
      </div>
    </div>
  );
};

export default Confirmation;
