import * as React from 'react';
import { createUseStyles } from 'react-jss';

import License from './License';
import BuildInfo from './BuildInfo';
import TitleBar from './TitleBar';
import { useClockService } from 'hooks/clock';
import { useOptionsInit } from 'hooks/options';
import { useUpdatesHistoryDetector } from 'hooks/updates';
import SystemTray from './system_tray/SystemTray';

const useStyles = createUseStyles({
  desktop: {
    height: '100%',
    background: 'url(/grain.png), #312f31',
    display: 'flex',
    flexDirection: 'column'
  },
  background: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative'
  },
  build_info: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 0
  },
  license: {
    position: 'absolute',
    bottom: 1,
    left: 3,
    zIndex: 0
  },
  systemTray: {
    flex: '0 0 200px'
  },
  titleBar: {
    flex: '0 0 32px',
    width: '100%',
    zIndex: 10000
  },
  tray: {},
  workspace: {
    flex: '1 1 auto',
    display: 'block',
    position: 'relative'
  }
});

const Desktop = () => {
  useClockService();
  useOptionsInit();
  useUpdatesHistoryDetector();
  const styles = useStyles();

  return (
    <div className={styles.desktop}>
      <TitleBar className={styles.titleBar} />
      <div className={styles.background}>
        <SystemTray className={styles.systemTray}>
          Roleplays &gt;
          <br />
          &nbsp;&nbsp;&nbsp;Roleplay Window 1
          <br />
          &nbsp;&nbsp;&nbsp;Roleplay Window 2
          <br />
          &nbsp;&nbsp;&nbsp;Roleplay Window 3
          <br />
          <br />
          Options &gt;
        </SystemTray>
        <div className={`${styles.workspace} workspace`}>
          <BuildInfo className={styles.build_info} />
          <License className={styles.license} />
        </div>
      </div>
    </div>
  );
};
export default Desktop;
