import * as React from 'react';
import { SwapHoriz } from '@material-ui/icons';

import Button, { ButtonType } from 'components/basic/Button';
import UseIconStyles from 'styles/IconStyles';
import { useInputVisibility, useOutputVisibility } from 'hooks/roleplay';
export interface EditorToggleButtonProps {
  session_id: string;
  className?: string;
}

const EditorToggleButton = ({
  session_id,
  className
}: EditorToggleButtonProps): React.ReactElement => {
  const styles = {
    icon: UseIconStyles()
  };
  const [is_input_visible, set_input_visbility] = useInputVisibility(
    session_id
  );
  const [is_output_visible, set_output_visbility] = useOutputVisibility(
    session_id
  );

  return (
    <Button
      className={className}
      type={ButtonType.Icon}
      on_click={() => {
        set_input_visbility(!is_input_visible);
        set_output_visbility(!is_output_visible);
      }}
      key='toggle_output'
      tooltip='Toggle Editor View'
    >
      <SwapHoriz className={styles.icon.face} />
    </Button>
  );
};

export default EditorToggleButton;
