import * as React from 'react';
import { createUseStyles } from 'react-jss';
import Button, { ButtonType } from './basic/Button';

import DesktopOptionsPanel from './options/desktop/DesktopOptionsPanel';
import RoleplayOptionsPanel from './options/roleplay/RoleplayOptionsPanel';

const useStyles = createUseStyles({
  window: {
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto'
  },
  tab_container: {
    flex: '0 0 85px',
    paddingTop: '10px'
  },
  tab_label: {
    height: '25px',
    boxSizing: 'border-box',
    marginBottom: '15px'
  },
  separator: {
    flex: '0 0 2px',
    background: '#332926',
    borderRight: '1px solid #cccccc'
  },
  selected: {
    borderBottom: '2px solid #cccccc'
  },
  tab_content: {
    paddingLeft: '15px',
    flex: 'auto'
  }
});

export interface OptionsWindowProps {
  className?: string;
}

const get_active_tab_content = (active_tab: string) => {
  switch (active_tab) {
    case 'desktop':
      return <DesktopOptionsPanel />;
    case 'roleplay':
      return <RoleplayOptionsPanel />;
  }
};

const OptionsWindow = ({
  className
}: OptionsWindowProps) => {
  const styles = useStyles();
  const [active_tab, set_active_tab] = React.useState('desktop');

  return (
    <div className={styles.window}>
      <div className={styles.tab_container}>
        <div className={`${styles.tab_label} ${active_tab === 'desktop' && styles.selected}`}>
          <Button type={ButtonType.FlatText} on_click={() => set_active_tab('desktop')}>Desktop</Button>
        </div>
        <div className={`${styles.tab_label} ${active_tab === 'roleplay' && styles.selected}`}>
          <Button type={ButtonType.FlatText} on_click={() => set_active_tab('roleplay')}>Roleplay</Button>
        </div>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.tab_content}>
        {get_active_tab_content(active_tab)}
      </div>
    </div>
  );
};
export default OptionsWindow;
