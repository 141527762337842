import { createUseStyles } from 'react-jss';

const scrollbarBackground = '#272627';
const scrollbarThumb = '#ffffff66';

const UseScrollbarStyles = createUseStyles({
  thin_scrollbar: {
    'scrollbar-width': 'thin',
    'scrollbar-color': `${scrollbarThumb} ${scrollbarBackground}`,
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: scrollbarBackground
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: scrollbarThumb
    }
  }
});

export default UseScrollbarStyles;
