import { Parser } from './../models/author';
import { createModel } from '@rematch/core';

import { RoleplaySession, ChatChannel } from 'models/roleplay';

export class RoleplayState {
  show_post_count: boolean;
  default_channel: ChatChannel;
  confirm_on_clear: boolean;
  sessions: { [key: string]: RoleplaySession };

  constructor() {
    this.show_post_count = true;
    this.default_channel = new ChatChannel('Say', 's');
    this.confirm_on_clear = false;
    this.sessions = {};
  }
}

const add_session = (
  state: RoleplayState,
  session: RoleplaySession
): RoleplayState => {
  if (state.sessions[session.session_id]) {
    return state;
  }
  let retval = { ...state };
  retval.sessions[session.session_id] = session;
  return retval;
};

const set_chat_channel = (
  state: RoleplayState,
  payload: {
    session_id: string;
    channel: ChatChannel;
  }
) => {
  let rp_session = state.sessions[payload.session_id];
  if (!rp_session) {
    return state;
  }
  rp_session.channel = payload.channel;
  let retval = { ...state };
  retval.sessions[payload.session_id] = rp_session;
  return retval;
};

const set_post_count_visibility = (
  state: RoleplayState,
  payload: {
    session_id: string;
    is_visible: boolean;
  }
) => {
  let retval = { ...state };
  let rp_session = state.sessions[payload.session_id];
  if (rp_session) {
    rp_session.show_post_count = payload.is_visible;
    retval.sessions[payload.session_id] = rp_session;
  }
  return retval;
};

const set_default_post_count_visibility = (
  state: RoleplayState,
  is_visible: boolean
) => {
  let retval = { ...state };
  retval.show_post_count = is_visible;
  return retval;
};

const set_default_chat_channel = (
  state: RoleplayState,
  channel: ChatChannel
) => {
  let retval = { ...state };
  retval.default_channel = channel;
  return retval;
};

const set_confirm_on_clear = (
  state: RoleplayState,
  confirm_on_clear: boolean
) => {
  let retval = { ...state };
  retval.confirm_on_clear = confirm_on_clear;
  return retval;
};

const set_input_visibility = (
  state: RoleplayState,
  payload: {
    session_id: string;
    is_visible: boolean;
  }
) => {
  let retval = { ...state };
  let rp_session = state.sessions[payload.session_id];
  if (rp_session) {
    rp_session.show_input = payload.is_visible;
  }
  return retval;
};

const set_output_visibility = (
  state: RoleplayState,
  payload: {
    session_id: string;
    is_visible: boolean;
  }
) => {
  let retval = { ...state };
  let rp_session = state.sessions[payload.session_id];
  if (rp_session) {
    rp_session.show_output = payload.is_visible;
  }
  return retval;
};

const set_input = (
  state: RoleplayState,
  payload: { session_id: string; input: string }
) => {
  let retval = { ...state };
  let rp_session = state.sessions[payload.session_id];
  if (rp_session) {
    rp_session.input = payload.input;
    rp_session.word_count = Parser.new(0).get_word_count(rp_session.input)
  }
  return retval;
};

const remove_session = (state: RoleplayState, session_id: string) => {
  let retval = { ...state };
  delete retval.sessions[session_id];
  return retval;
};

const set_new_iteration = (
  state: RoleplayState,
  payload: { session_id: string }
) => {
  let retval = { ...state };
  let rp_session = state.sessions[payload.session_id];
  if (rp_session) {
    rp_session.iteration = Math.random().toString();
  }
  return retval;
};

const roleplay = createModel({
  state: new RoleplayState(),
  reducers: {
    add_session,
    set_chat_channel,
    set_post_count_visibility,
    set_input_visibility,
    set_output_visibility,
    set_input,
    remove_session,
    set_new_iteration,
    set_default_post_count_visibility,
    set_default_chat_channel,
    set_confirm_on_clear
  },
  effects: {}
});

export default roleplay;
