import * as React from 'react';

import RawInputView from './RawInputView';
import ProcessedTextView from './ProcessedTextView';
import { useInputVisibility, useOutputVisibility } from 'hooks/roleplay';

interface RoleplayTextViewProps {
  session_id: string;
  className: string;
}

const RoleplayTextView = ({
  session_id,
  className
}: RoleplayTextViewProps): React.ReactElement => {
  const is_input_visible = useInputVisibility(session_id)[0];
  const is_output_visible = useOutputVisibility(session_id)[0];

  return (
    <div className={className}>
      {is_input_visible && <RawInputView session_id={session_id} />}
      {is_output_visible && <ProcessedTextView session_id={session_id} />}
    </div>
  );
};

export default RoleplayTextView;
