import * as React from 'react';

import { useLocalStorage } from './storage';
import UpdatesWindow from 'components/updates/UpdatesWindow';
import { get_current_version, Version } from 'models/version';
import { useWindowCreator, useWindowDestroyer } from './window';
import { ActiveWindow, WindowSection } from 'models/window';
import { Icons } from 'icons';

const last_used_version_key = 'last_used_version';

export const useUpdatesWindow = (): (() => void) => {
  const create_window = useWindowCreator();
  const destroy_window = useWindowDestroyer();

  return () => {
    const new_window_id = 'updates_window';

    let window: ActiveWindow = {
      title: 'Updates History',
      icon_path: Icons.Updates,
      window_id: new_window_id,
      section: WindowSection.Unsectioned,
      size: { width: 500, height: 400 },
      position: { x: 550, y: 50 },
      resizable: true,
      children: <UpdatesWindow window_id={new_window_id} />,
      z_index: 0,
      on_close: (window_id: string) => {
        destroy_window(window_id);
      }
    };
    create_window(window);
  };
};

export const useVersionCheck = () => {
  const ls = useLocalStorage();
  const last_used_version = ls.get_rich_object(
    Version.empty(),
    last_used_version_key
  );
  const current_version = get_current_version();
  return {
    current_version,
    last_used_version
  };
};

export const useUpdatesHistoryDetector = () => {
  const ls = useLocalStorage();
  const create_updates_window = useUpdatesWindow();
  const last_used_version = ls.get_rich_object(
    Version.empty(),
    last_used_version_key
  );
  const current_version = get_current_version();
  current_version.is_greater_than(last_used_version) && create_updates_window();
};

export const useLastUsedVersion = (): [Version, () => void] => {
  const ls = useLocalStorage();
  const version =
    ls.get_rich_object(Version.empty(), last_used_version_key) ??
    new Version(0, 0, 0);
  const set_version = () => {
    ls.set(last_used_version_key, get_current_version());
  };
  return [version, set_version];
};
