import * as React from 'react';
import { createUseStyles } from 'react-jss';

const useStyle = createUseStyles({
  license: {
    opacity: 0.5,
    textAlign: 'left',
    fontSize: '10pt'
  }
});

export interface LicenseProps {
  className?: string;
}

const License = ({ className }: LicenseProps): React.ReactElement => {
  const styles = useStyle();
  const year = new Date().getFullYear();
  return (
    <>
      <div className={`${className} ${styles.license} unselectable`}>
        FINAL FANTASY is a registered trademark of Square Enix Holdings Co.,
        Ltd.
        <br />
        FINAL FANTASY XIV © 2010 - {year} SQUARE ENIX CO., LTD. All Rights
        Reserved.
      </div>
    </>
  );
};
export default License;
