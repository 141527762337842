import { ICopyable } from 'models/ICopyable';
import * as current_version from './version.json';

export class Version extends ICopyable<Version> {
  major: number;
  minor: number;
  build: number;

  constructor(major: number, minor: number, build: number) {
    super();
    this.major = major;
    this.minor = minor;
    this.build = build;
  }

  static empty(): Version {
    return new Version(0, 0, 0);
  }

  copy_from(source: Version): void {
    this.major = source.major;
    this.minor = source.minor;
    this.build = source.build;
  }

  to_string(): string {
    return `${this.major}.${this.minor}.${this.build}`;
  }

  get_canonical_version(): number {
    return this.major * 10000 + this.minor * 100 + this.build;
  }

  is_greater_than(target: Version | null): boolean {
    return (
      !target || this.get_canonical_version() > target.get_canonical_version()
    );
  }
}

export function get_current_version(): Version {
  return new Version(
    current_version.major,
    current_version.minor,
    current_version.build
  );
}
