import * as React from 'react';

import { ActiveWindow, WindowCollection } from 'models/window';
import Window from './basic/window/Window';
import { useActiveWindows } from 'hooks/window';

const get_all_windows = (windows: WindowCollection): ActiveWindow[] => {
  let retval = [];
  for (let window_id in windows) {
    retval.push(windows[window_id]);
  }
  return retval;
};

const WindowRenderer = () => {
  const active_windows = useActiveWindows();
  const windows = get_all_windows(active_windows);

  return (
    <>
      {windows.map(window => (
        <Window key={window.window_id} {...window} />
      ))}
    </>
  );
};
export default WindowRenderer;
