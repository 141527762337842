import * as React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  update_block: {
    padding: '10px'
  },
  header: {
    fontSize: 'large',
    fontWeight: 'bold'
  },
  subheader: {
    fontSize: 'large'
  },
  date: {
    fontSize: 'x-small'
  }
});

export interface Release0202Props {
  className?: string;
}

const Release0300 = ({ className }: Release0202Props) => {
  const styles = useStyles();

  return (
    <div>
      <div className={styles.update_block}>
        <hr />
        <div>
          <div className={styles.subheader}>
            3.0.0 <span className={styles.date}>(2022-04-23)</span>
          </div>
          So I've settled into a new, much less stressful job and will be moving
          across states, but this was a good point to get a minor update out.
          <br />
          <br />
          I got a request for a small QoL update that was easy enough to take
          care of, but there's one extra bit of news regarding future development.
          <br />
          <br />
          As I was working on the toolkit and making plans for future updates,
          I started getting itches of ideas which just didn't fit into the
          toolkit but still seemed interesting to make and hopefully good for
          people to use.
          <br />
          <br />
          So that is where my next large project is born - Loredive. Once I move
          next month, I'll be able to start settling down again and get to it.
          What Loredive will eventually become is an online platform for solo
          and collaborative creative writing. That could range from writing
          your own RP posts by yourself, joining in a writing guild with dozens
          of others, or as large as co-writing your next novel with detailed
          timelines and character notes.
          <br />
          <br />
          Will it reach that within the next year? Oh absolutely not, but over
          time we'll steadily get there, and I'm hopeful that I can fill a niche
          someday that will help light or rekindle a fire for writing again in
          some of you.
          <br />
          <br />
          What does that mean for the toolkit though? Essentially, a lot of the
          social features I planned ages ago have been moved into the plan for
          Loredive instead, but there are still a few small quality of life
          updates to make before I'm out of planned improvements. This site will
          remain online no matter what happens with Loredive, and I'll never
          fully close development in case good ideas come in that I can take
          care of.
          <br />
          <br />
          I'm happy to have been able to help the folks who frequent here, and
          I'm hoping my lofty goals for the next few years can keep that ball
          rolling.
          <br />
          <br />
          Changes:
          <br />
          <ul>
            <li>
              <i>Added roleplay option to prompt for confirmation when trying
                to clear RP text.</i>
            </li>
          </ul>
          <br />
          <br />
          ~Auri &nbsp;
          <a href='mailto:auriana@ffxiv-toolkit.com'>
            &lt;auriana@ffxiv-toolkit.com&gt;
          </a>
        </div>
      </div>
    </div>
  );
};
export default Release0300;
