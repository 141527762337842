import { createUseStyles } from 'react-jss';

const UseIconStyles = createUseStyles({
  face: {
    fontSize: '12pt',
    position: 'absolute',
    top: '2px',
    left: '2px',
    textShadow: '0px 0px 1.5px black, 0px 0px 1.5px black, 0px 0px 1.5px black'
  }
});

export default UseIconStyles;
