import * as React from 'react';
import { createUseStyles } from 'react-jss';

import UseTextStyles from 'styles/TextStyles';
import DefaultChannelOption from './DefaultChannelOption';
import PostCountOption from './PostCountOption';
import PostLengthOption from './PostLengthOption';
import ConfirmOnClearOption from './ConfirmOnClearOption';

const useStyles = createUseStyles({
  header: {
    marginTop: '10px',
    marginBottom: '6px'
  },
  option: {
    paddingLeft: '16px',
    marginBottom: '3px'
  }
});

export interface RoleplayOptionsPanelProps {}

const RoleplayOptionsPanel = (props: RoleplayOptionsPanelProps): React.ReactElement => {
  const styles = {
    general: useStyles(),
    text: UseTextStyles()
  };

  return (
    <div>
      <div className={`${styles.text.header_color} ${styles.general.header}`}>
        Text Input
      </div>
      <div className={styles.general.option}>
        <DefaultChannelOption />
      </div>
      <div className={styles.general.option}>
        <PostCountOption />
      </div>
      <div className={styles.general.option}>
        <PostLengthOption />
      </div>
      <div className={styles.general.option}>
        <ConfirmOnClearOption />
      </div>
    </div>
  );
};

export default RoleplayOptionsPanel;
