import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { AddCircleOutlineOutlined } from '@material-ui/icons';

import { ActiveWindow, WindowCollection, WindowSection } from 'models/window';
import {
  useActiveWindows,
  useWindowSelector,
  useCheckActiveWindow
} from 'hooks/window';
import WindowRenderer from '../WindowRenderer';
import { useRoleplayCreator } from 'hooks/roleplay';
import Button, { ButtonType } from '../basic/Button';
import { Icons } from 'icons';
import UseScrollbarStyles from 'styles/ScrollbarStyles';
import TrayItem from './TrayItem';
import TraySubItem from './TraySubItem';
import QuickAccessBar from './QuickAccessBar';
import { useTextInputWindow } from 'hooks/text_input';

const get_rp_windows = (windows: WindowCollection): ActiveWindow[] => {
  let retval = [];
  for (let window_id in windows) {
    let window = windows[window_id];
    if (window.section === WindowSection.Roleplay) {
      retval.push(window);
    }
  }
  return retval;
};

const get_unsectioned_windows = (windows: WindowCollection): ActiveWindow[] => {
  let retval = [];
  for (let window_id in windows) {
    let window = windows[window_id];
    if (window.section === WindowSection.Unsectioned) {
      retval.push(window);
    }
  }
  return retval;
};

const useStyles = createUseStyles({
  active: {
    background: '#fff2',
    boxShadow: '0px 0px 2px 2px #fff2'
  },
  addIconContainer: {
    float: 'right',
    fontSize: '14pt !important'
  },
  addIcon: {
    '&:hover': {
      filter: 'drop-shadow(0 0 1px #fffa)'
    }
  },
  quickAccessContainer: {
    flex: '0 0 75px'
  },
  trayItemContainer: {
    overflowY: 'scroll',
    flex: '1 1 auto',
    position: 'relative'
  },
  trayItemCanvas: {
    position: 'absolute',
    width: '100%'
  },
  section: {
    height: '36px',
    fontSize: '12pt',
    paddingLeft: '5px',
    marginBottom: '5px',
    marginRight: '4px',
    'border-radius': '2px'
  },
  sectionEnd: {
    marginBottom: '20px'
  },
  sectionItem: {
    height: '24px',
    fontSize: '11pt',
    marginLeft: '15px',
    marginRight: '4px'
  },
  sectionBody: {
    width: '100%'
  },
  tray: {
    background: '#11111166',
    display: 'flex',
    flexDirection: 'column'
  }
});

export interface SystemTrayProps {
  className?: string;
  children?: any;
}

const SystemTray = ({ className, children }: SystemTrayProps) => {
  const styles = useStyles();
  const scrollbar_styles = UseScrollbarStyles();
  const active_windows = useActiveWindows();
  const rp_windows = get_rp_windows(active_windows);
  const other_windows = get_unsectioned_windows(active_windows);
  const create_roleplay_window = useRoleplayCreator();
  const create_text_input_window = useTextInputWindow();
  const select_window = useWindowSelector();
  const is_window_active = useCheckActiveWindow();

  return (
    <div className={`${className} ${styles.tray}`}>
      <QuickAccessBar className={styles.quickAccessContainer} />
      <div
        className={`${scrollbar_styles.thin_scrollbar} ${styles.trayItemContainer}`}
      >
        <div className={styles.trayItemCanvas}>
          <TrayItem className={styles.section} iconPath={Icons.Roleplays}>
            Roleplays
            <Button
              className={styles.addIconContainer}
              type={ButtonType.FlatIcon}
              tooltip='Create Roleplay'
              on_click={() =>
                create_text_input_window(
                  'New Roleplay',
                  title => create_roleplay_window(title),
                  'Create',
                  'Give your roleplay session a title.',
                  () => {},
                  'Cancel'
                )
              }
            >
              <AddCircleOutlineOutlined className={styles.addIcon} />
            </Button>
          </TrayItem>
          {rp_windows.map(window => {
            return (
              <TraySubItem
                key={window.window_id}
                className={`${styles.sectionItem} ${
                  is_window_active(window.window_id) ? styles.active : ''
                }`}
                window_id={window.window_id}
              >
                {window.title}
              </TraySubItem>
            );
          })}
          <div className={styles.sectionEnd}></div>
          {other_windows.map(window => {
            return (
              <div key={window.window_id}>
                <Button
                  className={styles.sectionBody}
                  type={ButtonType.FlatText}
                  on_click={() => select_window(window.window_id)}
                >
                  <TrayItem
                    className={`${styles.section} ${
                      is_window_active(window.window_id) ? styles.active : ''
                    }`}
                    iconPath={window.icon_path}
                  >
                    {window.title}
                  </TrayItem>
                </Button>
                <div className={styles.sectionEnd}></div>
              </div>
            );
          })}
        </div>
      </div>
      <div></div>
      <WindowRenderer />
    </div>
  );
};
export default SystemTray;
