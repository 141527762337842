import * as React from 'react';
import { FileCopyTwoTone } from '@material-ui/icons';
import { createUseStyles } from 'react-jss';

import { Post } from 'models/author';
import { usePostCountVisibility } from 'hooks/roleplay';
import Button, { ButtonType } from 'components/basic/Button';

const useStyles = createUseStyles({
  post: {
    display: 'flex',
    flexDirection: 'row',
    width: 'calc(100% - 50px)',
    margin: '10px 0 0 10px'
  },
  post_text: {
    flex: '1 1 auto',
    paddingRight: '10px'
  },
  copy_button: {
    flex: '0 0 25px'
  },
  rp_posts: {
    verticalAlign: 'top',
    marginTop: '10px',
    marginLeft: '5px',
    paddingRight: '10px'
  },
  copied: {
    opacity: '0.25'
  }
});

const send_text_to_clipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

interface ProcessedTextRowProps {
  session_id: string;
  post: Post;
}

const ProcessedTextRow = ({
  session_id,
  post
}: ProcessedTextRowProps): React.ReactElement => {
  const styles = useStyles();
  const [is_copied, set_is_copied] = React.useState(false);

  const show_post_count = usePostCountVisibility(session_id)[0];

  const raw_text =
    post.full_text +
    (show_post_count && post.is_partial ? ` ${post.post_number}` : '');

  return (
    <>
      <div className={`${styles.post} ${is_copied && styles.copied}`}>
        <div className={styles.post_text}>{raw_text}</div>
        <Button
          type={ButtonType.FlatIcon}
          className={styles.copy_button}
          tooltip='Copy'
          on_click={() => {
            send_text_to_clipboard(raw_text);
            set_is_copied(true);
          }}
        >
          <FileCopyTwoTone />
        </Button>
      </div>
      <hr />
    </>
  );
};

export default ProcessedTextRow;
