import * as React from 'react';

import ChannelButton from 'components/roleplay/ChannelButton';
import { useDefaultActiveChannel } from 'hooks/options';

export interface DefaultChannelOptionProps {}

const DefaultChannelOption = (
  props: DefaultChannelOptionProps
): React.ReactElement => {
  const [channel, set_channel] = useDefaultActiveChannel();
  return (
    <>
      New roleplay default channel:{' '}
      <ChannelButton on_selected={channel => set_channel(channel)} />{' '}
      {channel.label}
    </>
  );
};

export default DefaultChannelOption;
