import { createUseStyles } from 'react-jss';

const UseButtonStyles = createUseStyles({
  button: {
    display: 'inline-block',
    position: 'relative',
    userSelect: 'none',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  rounded: {
    background: `linear-gradient(
      to bottom,
      #636763 0%,
      #525152 47%,
      #393439 53%,
      #4a494a 100%
    )`,
    boxShadow: `0 0 1px black, 0 0 1px black, 0 0 1px black,
      inset 0.1px 0.5px 1px lightgrey, inset -0.1px -0.5px black`,
    borderRadius: '10px',
    '&:hover': {
      background: `linear-gradient(
        to bottom,
        #8c928c 0%,
        #727172 47%,
        #5e565e 53%,
        #616061 100%
      )`
    }
  },
  flat: {
    '&:hover': {}
  },
  icon_content: {
    height: '20px',
    width: '20px',
    '& svg': {
      height: '16px',
      width: '16px'
    }
  },
  bottom_padded: {
    marginBottom: '4px'
  },
  absolute: {
    position: 'absolute'
  },
  tooltip: {
    padding: '8px !important',
    zIndex: '999999 !important',
    borderRadius: '10px !important',
    fontSize: '11pt !important'
  },
  rightTooltip: {
    marginLeft: '5px !important'
  },
  bottomTooltip: {
    marginTop: '5px !important'
  },
});

export default UseButtonStyles;
