import * as React from 'react';
import { createUseStyles } from 'react-jss';

import Clock, { ClockElement } from './Clock';
import { useEorzeanClockOption, useLocalClockOption, useServerClockOption } from 'hooks/options';
import { ClockStyle, ClockType } from 'models/clock';

const useStyles = createUseStyles({
  wrapper: {}
});

export interface ClockContainerProps {}

const ClockContainer = (props: ClockContainerProps): React.ReactElement => {
  const styles = useStyles();

  const is_eorzean_clock_visible: boolean = useEorzeanClockOption()[0];
  const is_local_clock_visible: boolean = useLocalClockOption()[0];
  const is_server_clock_visible: boolean = useServerClockOption()[0];

  let clocks: ClockElement[] = [];
  is_eorzean_clock_visible &&
    clocks.push({
      key: 'eorzea',
      type: ClockType.Eorzea,
      style: ClockStyle.Half
    });
  is_local_clock_visible &&
    clocks.push({
      key: 'local',
      type: ClockType.Local,
      style: ClockStyle.Half
    });
  is_server_clock_visible &&
    clocks.push({
      key: 'server',
      type: ClockType.Server,
      style: ClockStyle.Half
    });

  return (
    <>
      <div className={styles.wrapper}>
        {clocks.map(clock => {
          return (
            <Clock key={clock.key} type={clock.type} style={clock.style} />
          );
        })}
      </div>
    </>
  );
};

export default ClockContainer;
