import { createModel } from '@rematch/core';

export class ParserState {
  max_post_length: number;

  constructor() {
    this.max_post_length = 475;
  }
}

const set_max_post_length = (state: ParserState, length: number) => {
  let retval = { ...state };
  retval.max_post_length = length;
  return retval;
};

const roleplay = createModel({
  state: new ParserState(),
  reducers: {
    set_max_post_length
  },
  effects: {}
});

export default roleplay;
