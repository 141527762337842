import { useWindowDestroyer } from 'hooks/window';
import * as React from 'react';
import { createUseStyles } from 'react-jss';

import Button, { ButtonType } from './Button';

const useStyles = createUseStyles({
  contentContainer: {
    width: '100%',
    padding: 5
  },
  textbox: {
    width: '100%',
    font: {
      family: 'Calibri, Trebuchet MS, sans-serif'
    },
    background: '#272627',
    color: '#dddddd',
    border: {
      width: 0,
      radius: 4
    },
    boxShadow: 'inset -1px -1px 0.5px #ffffff22, inset 1px 1px 0.5px #00000022',
    resize: 'none',
    outline: '0 none',
    boxSizing: 'border-box',
    padding: 3
  },
  description: {
    marginTop: 5,
    marginBottom: 5
  },
  buttons: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 10,
    marginBottom: 5,
    textAlign: 'right'
  },
  button: {
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: 15
  }
});

interface TextInputProps {
  window_id: string;
  description?: string;
  on_accept: (input: string) => void;
  accept_label: string;
  on_decline?: () => void;
  decline_label?: string;
  placeholder?: string;
}

const TextInput = ({
  window_id,
  description,
  on_accept,
  accept_label,
  on_decline,
  decline_label,
  placeholder
}: TextInputProps): React.ReactElement => {
  const styles = useStyles();
  const [has_focused, set_has_focused] = React.useState(false);
  const [input, set_input] = React.useState('');
  const destroy_window = useWindowDestroyer();
  const input_ref = React.useRef<HTMLInputElement | null>(null);

  React.useLayoutEffect(() => {
    if (!has_focused) {
      input_ref?.current?.focus();
      set_has_focused(true);
    }
  }, [has_focused]);

  const accept_action = () => {
    on_accept(input);
    destroy_window(window_id);
  };

  const decline_action = () => {
    on_decline && on_decline();
    destroy_window(window_id);
  };

  const on_input_change = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    set_input(event.target.value);
    event.persist();
  };

  const on_key_down = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      accept_action();
    }
    event.persist();
  };

  return (
    <div className={styles.contentContainer}>
      {description && <div className={styles.description}>{description}</div>}
      <input
        ref={input_ref}
        placeholder={placeholder}
        className={styles.textbox}
        onChange={on_input_change}
        onKeyDown={on_key_down}
      ></input>
      <div className={styles.buttons}>
        {decline_label && (
          <Button
            className={styles.button}
            type={ButtonType.Text}
            on_click={decline_action}
          >
            {decline_label}
          </Button>
        )}
        <Button
          className={styles.button}
          type={ButtonType.Text}
          on_click={accept_action}
        >
          {accept_label}
        </Button>
      </div>
    </div>
  );
};

export default TextInput;
