import * as React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  update_block: {
    padding: '10px'
  },
  header: {
    fontSize: 'large',
    fontWeight: 'bold'
  },
  subheader: {
    fontSize: 'large'
  },
  date: {
    fontSize: 'x-small'
  }
});

export interface Release0202Props {
  className?: string;
}

const Release0202 = ({ className }: Release0202Props) => {
  const styles = useStyles();

  return (
    <div>
      <div className={styles.update_block}>
        <hr />
        <div>
          <div className={styles.subheader}>
            2.2.3 <span className={styles.date}>(2020-12-24)</span>
          </div>
          Happy holidays everyone!
          <br />
          Fixes:
          <br />
          <ul>
            <li>
              <i>Processed text wasn't using the chosen chat channel. (Thanks Rhosie!)</i>
            </li>
            <li>
              <i>Opening the chat channel menu when multiple chat channel buttons existed caused a crash. (Indirect thanks Rhosie!)</i>
            </li>
          </ul>
          <hr />
          <div className={styles.subheader}>
            2.2.2 <span className={styles.date}>(2020-12-22)</span>
          </div>
          Well, it's finally here.
          <br />
          I intended on having this released by the end of 2019, and well..
          <br />
          2020 was 2020..
          <br />
          <br />
          This is the first official release of the newly rewritten toolkit! As
          you'll notice, not very much has changed! (hopefully nothing broke for
          you)
          <br />
          <br />
          The technical description is that I rewrote everything from Angular to
          React for modularity and general appreciation for the framework.
          <br />
          <br />
          With this release, I'm now in a position to release more rapid updates
          that will hopefully improve the writing process drastically. This was
          also amusingly the first and last major release for Update 2. Update 3
          is currently planned to further improve the writing process with a
          number of quality of life improvements that I hope line up with the RP
          community's needs.
          <br />
          <br />
          If not, I am always open for thoughts on features and improvements,
          and you can drop me a line at my email below. I always love to hear
          how people are enjoying my labor of love!
          <br />
          <br />
          Change log:
          <br />
          <br />
          <ul>
            <li>
              <i>Rewrote everything</i>
            </li>
            <li>Added server time clock</li>
            <li>Added logo button with system menu</li>
            <li>
              Added ability to open multiple roleplay windows from the logo menu
            </li>
            <li>Added options window to the logo menu</li>
            <li>Added options for visibility of clock types</li>
            <li>
              Added option for default chat channel when a new roleplay window
              is opened
            </li>
            <li>
              Added option for maximum post length before creating a new post
              (for smaller posters or other online games
            </li>
            <li>Added updates window</li>
            <li>
              Heavily reworked text parser
              <br />
              - Sentences that are longer than the maximum post length will
              break along punctuation
              <br />- Quotes whose length will cause the post length to exceed
              maximum will be broken out into a new post in full
            </li>
            <li>
              Google analytics removed to reduce tracking
              <br />
              <i>
                (Please consider whitelisting this site on your adblocker! Our
                tracker is privacy focused and seeing site activity helps me a
                lot!)
              </i>
            </li>
            <li>
              Future improvement: windows will have usability improved regarding
              bringing the active window to the foreground
            </li>
          </ul>
          <br />
          <br />
          ~Auri &nbsp;
          <a href='mailto:auriana@ffxiv-toolkit.com'>
            &lt;auriana@ffxiv-toolkit.com&gt;
          </a>
        </div>
      </div>
    </div>
  );
};
export default Release0202;
