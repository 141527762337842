import * as React from 'react';

import { createUseStyles } from "react-jss";

const useTrayItemStyles = createUseStyles({
  itemContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  iconContainer: {
    flex: '0 0 42px'
  },
  icon: {
    height: '42px',
    width: '42px',
    verticalAlign: 'middle'
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column'
  },
  content: {
    flex: '1 1 34px'
  },
  contentLine: {
    flex: '0 0 5px',
    background: '#fffa',
    marginBottom: '3px',
    marginRight: '5px'
  }
});

interface TrayItemProps {
  className?: string;
  iconPath: string;
  children?: any;
}

const TrayItem = ({ className, iconPath, children }: TrayItemProps) => {
  let styles = useTrayItemStyles();
  return (
    <div className={`${className} ${styles.itemContainer}`}>
      <div className={styles.iconContainer}>
        <img className={styles.icon} src={iconPath} alt='' />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.content}>{children}</div>
        <div className={styles.contentLine}></div>
      </div>
    </div>
  );
};

export default TrayItem;