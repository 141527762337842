import { createModel } from '@rematch/core';

export interface DesktopState {
  is_eorzean_clock_visible: boolean;
  is_local_clock_visible: boolean;
  is_server_clock_visible: boolean;
}

const set_eorzean_clock_visibility = (
  state: DesktopState,
  is_visible: boolean
) => {
  let retval = { ...state };
  retval.is_eorzean_clock_visible = is_visible;
  return retval;
};

const set_local_clock_visibility = (
  state: DesktopState,
  is_visible: boolean
) => {
  let retval = { ...state };
  retval.is_local_clock_visible = is_visible;
  return retval;
};

const set_server_clock_visibility = (
  state: DesktopState,
  is_visible: boolean
) => {
  let retval = { ...state };
  retval.is_server_clock_visible = is_visible;
  return retval;
};

const desktop = createModel({
  state: {
    is_eorzean_clock_visible: true,
    is_local_clock_visible: true,
    is_server_clock_visible: true
  },
  reducers: {
    set_eorzean_clock_visibility,
    set_local_clock_visibility,
    set_server_clock_visibility
  },
  effects: {}
});

export default desktop;
