import { createUseStyles } from 'react-jss';

const UseMenuStyles = createUseStyles({
  body: {
    backgroundColor: '#444444',
    borderRadius: '4px',
    boxShadow:
      '0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12)',
    zIndex: 10000
  },
  item: {
    '&:not(.react-contexify__item--disabled):hover': {
      backgroundColor: '#272627'
    }
  }
});

export default UseMenuStyles;
