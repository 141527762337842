import * as React from 'react';

import Button, { ButtonType } from 'components/basic/Button';
import UseFormStyles from 'styles/FormStyles';
import { useConfirmOnClearOption } from 'hooks/options';

export interface ConfirmOnClearOptionProps {
}

const ConfirmOnClearOption = (props: ConfirmOnClearOptionProps): React.ReactElement => {
  const [confirm_on_clear, set_confirm_on_clear] = useConfirmOnClearOption();
  const styles = UseFormStyles();

  const checkbox_style = confirm_on_clear ? styles.checked : styles.unchecked;

  return (
    <Button
      type={ButtonType.FlatText}
      on_click={() => set_confirm_on_clear(!confirm_on_clear)}
    >
      <div className={`${styles.checkbox} ${checkbox_style}`}></div>
      Confirm before clearing roleplay text
    </Button>
  );
};

export default ConfirmOnClearOption;
