import * as React from 'react';
import { ReactNode, MouseEvent } from 'react';
import ReactTooltip from 'react-tooltip';

import { contextMenu } from 'react-contexify';
import 'styles/contexify.css';
import UseButtonStyles from 'styles/ButtonStyles';
import UseTextStyles from 'styles/TextStyles';
import { create_new_id } from 'util/rand';

export enum ButtonType {
  Text,
  Icon,
  FlatText,
  FlatIcon
}

interface ButtonProps {
  className?: string;
  type: ButtonType;
  children: ReactNode;
  on_click?: (event: MouseEvent<HTMLDivElement>) => void;
  menu_id?: string;
  absolute?: boolean;
  tooltip?: string;
}

const get_on_click = ({ on_click, menu_id }: ButtonProps) => {
  if (on_click) {
    return on_click;
  } else if (menu_id) {
    return (event: React.MouseEvent) => {
      event.preventDefault();
      contextMenu.show({ id: menu_id ?? '', event });
    };
  } else {
    return () => {};
  }
};

const get_class_names = ({ type, absolute }: ButtonProps) => {
  const styles = {
    button: UseButtonStyles(),
    text: UseTextStyles()
  };

  let button_type: string;
  let appearance: string;

  switch (type) {
    case ButtonType.Icon:
      button_type = styles.button.icon_content;
      appearance = styles.button.rounded;
      break;
    case ButtonType.FlatIcon:
      button_type = styles.button.icon_content;
      appearance = styles.button.flat;
      break;
    case ButtonType.Text:
      button_type = styles.text.item_color;
      appearance = styles.button.rounded;
      break;
    case ButtonType.FlatText:
      button_type = styles.text.item_color;
      appearance = styles.button.flat;
      break;
    default:
      return null;
  }

  return `${styles.button.button} ${appearance} ${button_type} ${absolute &&
    styles.button.absolute}`;
};

const Button = (props: ButtonProps): React.ReactElement => {
  const styles = UseButtonStyles();

  const [element_id] = React.useState(create_new_id());

  if (props.tooltip) {
    return (
      <>
        <div
          className={`${props.className} ${get_class_names(props)}`}
          onClick={get_on_click(props)}
          data-for={`tooltip${element_id}`}
          data-tip={props.tooltip}
        >
          {props.children}
        </div>
        {/* https://github.com/wwayne/react-tooltip#readme */}
        <ReactTooltip
          id={`tooltip${element_id}`}
          className={`${styles.tooltip} ${styles.rightTooltip}`}
          place='right'
          type='dark'
          effect='solid'
          multiline={true}
          html={true}
          delayShow={350}
        />
      </>
    );
  } else {
    return (
      <div
        className={`${props.className} ${get_class_names(props)}`}
        onClick={get_on_click(props)}
      >
        {props.children}
      </div>
    );
  }
};

export default Button;
